import realProgressLogoTransparent from "../assets/RealProgressNameLogoTransparent.png";

const RealProgress = () => {
  return (
    <>
      <div
        className="realprogress-card"
        style={{
          minHeight: 210,
          borderRadius: 15,
          maxWidth: 450,
          minWidth: 360,
          marginBottom: 25,
          float: "left",
          backgroundColor: "white",
        }}
      >
        {/* Logo */}
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ padding: "10px 0", textAlign: "center" }}
        >
          <img
            src={realProgressLogoTransparent}
            alt="RealProgress Logo"
            style={{
              maxWidth: "275px",
              height: "auto",
              margin: "0",
              paddingTop: "5px",
            }}
          />
        </div>

        {/* Short Description */}
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ margin: "0", padding: "0 10px" }}
        >
          <p style={{ fontSize: "18px", textAlign: "center" }}>
            Making off-market real estate deals more transparent to investors
            while helping lay investors build wealth.
          </p>
        </div>

        {/* Website Link */}
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ margin: "10px 0", padding: "0" }}
        >
          <a
            href="https://realprogress.co"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", margin: "0", padding: "0" }}
          >
            <button type="button" style={{
              margin: "5px 0",
              padding: "10px",
              width: "100%",
              backgroundColor: "#F0B100",
              border: "none",
              color: "white",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              transitionDuration: "0.4s",
              cursor: "pointer",
            }}>
              Get Early Access Here
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default RealProgress;