import ContactList from "./ContactList";
import Image from "./Image";

const Contact = ({ contactOptionListArr }) => {
  return (
    <>
      <div className="contact-card">
        <div className="wrap-img">
          <Image />
        </div>
        <div className="signature">
          <h2><b>Dylan Cunningham</b></h2>
          {/* <h3></h3> */}
          <h4>Data Science | Business | Investments</h4>
          <h5>Ask me about Investing, Trading, Real Estate, Business, AI, Data Science, & Technology.</h5>
        </div>
        <ContactList contactOptionListArr={contactOptionListArr} />
      </div>
    </>
  );
};

export default Contact;
