import Contact from "./Contact";
// import Content from "./Content";
import Funding from "./Funding";
import RealProgress from "./RealProgress";
// import Image from "./Image";
// import { IoIosArrowDown } from "react-icons/io";

const Home = ({ contactOptionListArr }) => {

  const smoothScroll = (pixelsToScroll, duration) => {
    const start = window.pageYOffset;
    const increment = pixelsToScroll / duration * 10;
    let lastPosition = window.pageYOffset;
    let stop = false;

    const handleWheel = () => {
      stop = true;
    };

    window.addEventListener('wheel', handleWheel);

    const animateScroll = () => {
      if (stop || window.pageYOffset >= start + pixelsToScroll || window.pageYOffset < lastPosition) {
        window.removeEventListener('wheel', handleWheel);
        return;
      }
      window.scrollBy(0, increment);
      lastPosition = window.pageYOffset;
      window.requestAnimationFrame(animateScroll);
    };

    animateScroll();
  };

  return (
    <>
      <div className="container" id="form-container">
        <div className="home-page row d-flex justify-content-center">
          <Contact contactOptionListArr={contactOptionListArr} />
          <div className="stacked-cards">
            <RealProgress />
            <Funding />
            {/* <Content /> */}
          </div>
        </div>
      </div>
      {/* <div className="scroll-arrow" onClick={() => smoothScroll(500, 500)}>
        <IoIosArrowDown size={30} />
      </div> */}
    </>
  );
};

export default Home;